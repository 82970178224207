import { FC } from "react";
import { IIconComponent } from "@/services/types/types";
export const UnderlineIcon: FC<IIconComponent> = ({
  className
}) => {
  return <svg fill="#000000" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.346 27.346" className={className} data-sentry-element="svg" data-sentry-component="UnderlineIcon" data-sentry-source-file="underline-icon.tsx">
      <g data-sentry-element="g" data-sentry-source-file="underline-icon.tsx">
        <g id="c155_underline" data-sentry-element="g" data-sentry-source-file="underline-icon.tsx">
          <path d="M25.17,0.624H2.175C0.973,0.624,0,1.598,0,2.8v21.747c0,1.199,0.973,2.175,2.175,2.175H25.17
			c1.199,0,2.176-0.976,2.176-2.175V2.8C27.346,1.598,26.369,0.624,25.17,0.624z M25.213,22.867c0,1.011-0.824,1.837-1.838,1.837
			H3.938c-1.018,0-1.839-0.826-1.839-1.837V4.48c0-1.013,0.821-1.837,1.839-1.837h19.437c1.014,0,1.838,0.824,1.838,1.837V22.867z" data-sentry-element="path" data-sentry-source-file="underline-icon.tsx" />
          <rect x="7.817" y="20.727" width="11.71" height="1.796" data-sentry-element="rect" data-sentry-source-file="underline-icon.tsx" />
          <path d="M17.59,13.357c0,1.188-0.322,2.095-0.969,2.718c-0.646,0.625-1.588,0.934-2.825,0.934c-1.295,0-2.29-0.309-2.988-0.934
			c-0.704-0.623-1.052-1.529-1.052-2.718v-8.53H7.817v8.53c0,0.889,0.135,1.657,0.408,2.309c0.273,0.645,0.663,1.181,1.175,1.606
			c0.509,0.426,1.138,0.737,1.876,0.936c0.742,0.197,1.578,0.3,2.521,0.3c1.821,0,3.231-0.434,4.233-1.301
			c0.998-0.867,1.498-2.149,1.498-3.85v-8.53H17.59V13.357z" data-sentry-element="path" data-sentry-source-file="underline-icon.tsx" />
        </g>
        <g id="Capa_1_277_" data-sentry-element="g" data-sentry-source-file="underline-icon.tsx">
        </g>
      </g>
    </svg>;
};