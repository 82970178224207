import { IIconComponent } from "@/services/types/types";
import { FC } from "react";
export const RestoreIcon: FC<IIconComponent> = ({
  className,
  fill,
  size
}) => {
  return <svg xmlns="http://www.w3.org/2000/svg" width={size || "24"} height={size || "24"} viewBox="0 0 24 24" fill="none" className={className} data-sentry-element="svg" data-sentry-component="RestoreIcon" data-sentry-source-file="restore-icon.tsx">
      <path d="M18.6106 9.75708C19.098 9.57846 19.6427 9.83137 19.7627 10.3364C20.1117 11.8055 20.0764 13.3478 19.6523 14.8077C19.1402 16.5707 18.0878 18.1216 16.6483 19.235C15.2087 20.3483 13.4571 20.9659 11.6483 20.998C9.83946 21.03 8.06772 20.4749 6.59084 19.4133C5.11395 18.3517 4.00896 16.839 3.4366 15.0952C2.86425 13.3515 2.85439 11.4677 3.40846 9.71791C3.96252 7.9681 5.05162 6.44355 6.51731 5.36603C7.98299 4.28851 9.74882 3.71424 11.5579 3.72676L11.5452 5.61963C10.1326 5.60985 8.75385 6.05825 7.60941 6.8996C6.46497 7.74095 5.61458 8.93135 5.18195 10.2976C4.74932 11.6639 4.75702 13.1348 5.20393 14.4964C5.65084 15.8579 6.51364 17.0391 7.66682 17.868C8.82001 18.6969 10.2034 19.1304 11.6158 19.1054C13.0282 19.0803 14.3959 18.5981 15.5199 17.7288C16.6439 16.8595 17.4656 15.6484 17.8655 14.2719C18.1791 13.1924 18.2204 12.0553 17.9925 10.9637C17.888 10.4628 18.1302 9.93315 18.6106 9.75708Z" fill={fill || "#82C21D"} data-sentry-element="path" data-sentry-source-file="restore-icon.tsx" />
      <path d="M15.0228 4.24252C15.2788 4.44271 15.2788 4.83002 15.0228 5.0302L12.3081 7.15354C11.9799 7.41026 11.5001 7.1764 11.5001 6.7597L11.5001 2.51303C11.5001 2.09633 11.9799 1.86247 12.3081 2.11919L15.0228 4.24252Z" fill={fill || "#82C21D"} data-sentry-element="path" data-sentry-source-file="restore-icon.tsx" />
    </svg>;
};