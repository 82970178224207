import { FC } from "react";
import { IIconComponent } from "@/services/types/types";
export const AlignJustifyIcon: FC<IIconComponent> = ({
  className
}) => {
  return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} data-sentry-element="svg" data-sentry-component="AlignJustifyIcon" data-sentry-source-file="align-justify-icon.tsx">
      <path d="M5 5.25C4.58579 5.25 4.25 5.58579 4.25 6C4.25 6.41421 4.58579 6.75 5 6.75H19C19.4142 6.75 19.75 6.41421 19.75 6C19.75 5.58579 19.4142 5.25 19 5.25H5Z" fill="#2D264B" data-sentry-element="path" data-sentry-source-file="align-justify-icon.tsx" />
      <path d="M5 9.25C4.58579 9.25 4.25 9.58579 4.25 10C4.25 10.4142 4.58579 10.75 5 10.75H19C19.4142 10.75 19.75 10.4142 19.75 10C19.75 9.58579 19.4142 9.25 19 9.25H5Z" fill="#2D264B" data-sentry-element="path" data-sentry-source-file="align-justify-icon.tsx" />
      <path d="M5 13.25C4.58579 13.25 4.25 13.5858 4.25 14C4.25 14.4142 4.58579 14.75 5 14.75H19C19.4142 14.75 19.75 14.4142 19.75 14C19.75 13.5858 19.4142 13.25 19 13.25H5Z" fill="#2D264B" data-sentry-element="path" data-sentry-source-file="align-justify-icon.tsx" />
      <path d="M5 17.25C4.58579 17.25 4.25 17.5858 4.25 18C4.25 18.4142 4.58579 18.75 5 18.75H19C19.4142 18.75 19.75 18.4142 19.75 18C19.75 17.5858 19.4142 17.25 19 17.25H5Z" fill="#2D264B" data-sentry-element="path" data-sentry-source-file="align-justify-icon.tsx" />
    </svg>;
};