import { IIconComponent } from "../../../services/types/types";
import { FC } from "react";
export const TagIcon: FC<IIconComponent> = ({
  className,
  fill
}) => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="20" height="24" viewBox="0 0 20 24" fill="none" className={className} data-sentry-element="svg" data-sentry-component="TagIcon" data-sentry-source-file="tag-icon.tsx">
      <path d="M20 9.6C20 17.6 11.6667 24 10 24C8.33333 24 0 17.6 0 9.6C0 4.29807 4.47715 0 10 0C15.5228 0 20 4.29807 20 9.6Z" fill={fill ? fill : "#FFCC00"} data-sentry-element="path" data-sentry-source-file="tag-icon.tsx" />
      <circle cx="10" cy="10" r="5" fill="white" data-sentry-element="circle" data-sentry-source-file="tag-icon.tsx" />
    </svg>;
};
export const TagTransparentIcon: FC<IIconComponent> = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" data-sentry-element="svg" data-sentry-component="TagTransparentIcon" data-sentry-source-file="tag-icon.tsx">
      <path d="M19 10C19 13.0057 17.4887 15.7834 15.7366 17.8541C14.8676 18.8811 13.9648 19.7031 13.2158 20.26C12.8403 20.5392 12.518 20.741 12.2704 20.8679C12.1324 20.9387 12.0447 20.972 12 20.9875C11.9553 20.972 11.8676 20.9387 11.7296 20.8679C11.482 20.741 11.1597 20.5392 10.7842 20.26C10.0352 19.7031 9.13244 18.8811 8.26339 17.8541C6.51132 15.7834 5 13.0057 5 10C5 6.13401 8.13401 3 12 3C15.866 3 19 6.13401 19 10Z" stroke="#1D1D1D" strokeWidth="2" data-sentry-element="path" data-sentry-source-file="tag-icon.tsx" />
      <circle cx="12" cy="10" r="3" stroke="#1D1D1D" strokeWidth="2" data-sentry-element="circle" data-sentry-source-file="tag-icon.tsx" />
    </svg>;
};