import { FC } from "react";
import { IIconComponent } from "@/services/types/types";
export const AlignCenterIcon: FC<IIconComponent> = ({
  className
}) => {
  return <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="AlignCenterIcon" data-sentry-source-file="align-center-icon.tsx">
      <path d="M5 5.25C4.58579 5.25 4.25 5.58579 4.25 6C4.25 6.41421 4.58579 6.75 5 6.75H19C19.4142 6.75 19.75 6.41421 19.75 6C19.75 5.58579 19.4142 5.25 19 5.25H5Z" fill="#2D264B" data-sentry-element="path" data-sentry-source-file="align-center-icon.tsx" />
      <path d="M9 9.25C8.58579 9.25 8.25 9.58579 8.25 10C8.25 10.4142 8.58579 10.75 9 10.75H15C15.4142 10.75 15.75 10.4142 15.75 10C15.75 9.58579 15.4142 9.25 15 9.25H9Z" fill="#2D264B" data-sentry-element="path" data-sentry-source-file="align-center-icon.tsx" />
      <path d="M5 13.25C4.58579 13.25 4.25 13.5858 4.25 14C4.25 14.4142 4.58579 14.75 5 14.75H19C19.4142 14.75 19.75 14.4142 19.75 14C19.75 13.5858 19.4142 13.25 19 13.25H5Z" fill="#2D264B" data-sentry-element="path" data-sentry-source-file="align-center-icon.tsx" />
      <path d="M9 17.25C8.58579 17.25 8.25 17.5858 8.25 18C8.25 18.4142 8.58579 18.75 9 18.75H15C15.4142 18.75 15.75 18.4142 15.75 18C15.75 17.5858 15.4142 17.25 15 17.25H9Z" fill="#2D264B" data-sentry-element="path" data-sentry-source-file="align-center-icon.tsx" />
    </svg>;
};