import { IIconComponent } from "../../../services/types/types";
import { FC } from "react";
export const PayErrorIcon: FC<IIconComponent> = ({
  className
}) => {
  return <svg width="59" height="59" viewBox="0 0 59 59" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} data-sentry-element="svg" data-sentry-component="PayErrorIcon" data-sentry-source-file="pay-error-icon.tsx">
      <circle cx="29.5" cy="29.5" r="26.5" stroke="#AC0401" strokeWidth="6" data-sentry-element="circle" data-sentry-source-file="pay-error-icon.tsx" />
      <path fillRule="evenodd" clipRule="evenodd" d="M37.3758 43L16 21.6242L21.6242 16L43 37.3758L37.3758 43Z" fill="#AC0401" data-sentry-element="path" data-sentry-source-file="pay-error-icon.tsx" />
      <path fillRule="evenodd" clipRule="evenodd" d="M21.6242 43L43 21.6242L37.3758 16L16 37.3758L21.6242 43Z" fill="#AC0401" data-sentry-element="path" data-sentry-source-file="pay-error-icon.tsx" />
    </svg>;
};