import { IIconComponent } from "@/services/types/types";
import { FC } from "react";
export const GooglePlayMiniIcon: FC<IIconComponent> = ({
  className
}) => {
  return <svg className={className} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 31 34" fill="none" data-sentry-element="svg" data-sentry-component="GooglePlayMiniIcon" data-sentry-source-file="google-play-mini-icon.tsx">
      <g clipPath="url(#clip0_565_984)" data-sentry-element="g" data-sentry-source-file="google-play-mini-icon.tsx">
        <path d="M0.617482 0.522123C0.226354 0.921873 0 1.54425 0 2.35025V31.0997C0 31.9057 0.226354 32.5281 0.617482 32.9279L0.714017 33.0156L17.2146 16.9119V16.5316L0.714017 0.427875L0.617482 0.522123Z" fill="url(#paint0_linear_565_984)" data-sentry-element="path" data-sentry-source-file="google-play-mini-icon.tsx" />
        <path d="M22.705 22.2812L17.2109 16.9106V16.5304L22.7117 11.1598L22.8349 11.2296L29.3492 14.8485C31.2083 15.8755 31.2083 17.5655 29.3492 18.599L22.8349 22.2114L22.705 22.2812V22.2812Z" fill="url(#paint1_linear_565_984)" data-sentry-element="path" data-sentry-source-file="google-play-mini-icon.tsx" />
        <path d="M22.8305 22.207L17.2065 16.7162L0.609375 32.9223C1.22686 33.556 2.2338 33.6324 3.3789 32.9987L22.8305 22.207" fill="url(#paint2_linear_565_984)" data-sentry-element="path" data-sentry-source-file="google-play-mini-icon.tsx" />
        <path d="M22.8305 11.2279L3.3789 0.436255C2.2338 -0.190995 1.22686 -0.114622 0.609375 0.519127L17.2065 16.7188L22.8305 11.2279Z" fill="url(#paint3_linear_565_984)" data-sentry-element="path" data-sentry-source-file="google-play-mini-icon.tsx" />
      </g>
      <defs data-sentry-element="defs" data-sentry-source-file="google-play-mini-icon.tsx">
        <linearGradient id="paint0_linear_565_984" x1="15.7496" y1="31.3991" x2="-6.06129" y2="9.05978" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="google-play-mini-icon.tsx">
          <stop offset="0" stopColor="#00A0FF" data-sentry-element="stop" data-sentry-source-file="google-play-mini-icon.tsx" />
          <stop offset="0.0066" stopColor="#00A1FF" data-sentry-element="stop" data-sentry-source-file="google-play-mini-icon.tsx" />
          <stop offset="0.2601" stopColor="#00BEFF" data-sentry-element="stop" data-sentry-source-file="google-play-mini-icon.tsx" />
          <stop offset="0.5122" stopColor="#00D2FF" data-sentry-element="stop" data-sentry-source-file="google-play-mini-icon.tsx" />
          <stop offset="0.7604" stopColor="#00DFFF" data-sentry-element="stop" data-sentry-source-file="google-play-mini-icon.tsx" />
          <stop offset="1" stopColor="#00E3FF" data-sentry-element="stop" data-sentry-source-file="google-play-mini-icon.tsx" />
        </linearGradient>
        <linearGradient id="paint1_linear_565_984" x1="31.7701" y1="16.7187" x2="-0.448085" y2="16.7187" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="google-play-mini-icon.tsx">
          <stop stopColor="#FFE000" data-sentry-element="stop" data-sentry-source-file="google-play-mini-icon.tsx" />
          <stop offset="0.4087" stopColor="#FFBD00" data-sentry-element="stop" data-sentry-source-file="google-play-mini-icon.tsx" />
          <stop offset="0.7754" stopColor="#FFA500" data-sentry-element="stop" data-sentry-source-file="google-play-mini-icon.tsx" />
          <stop offset="1" stopColor="#FF9C00" data-sentry-element="stop" data-sentry-source-file="google-play-mini-icon.tsx" />
        </linearGradient>
        <linearGradient id="paint2_linear_565_984" x1="19.7723" y1="13.7312" x2="-9.80498" y2="-16.5628" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="google-play-mini-icon.tsx">
          <stop offset="0" stopColor="#FF3A44" data-sentry-element="stop" data-sentry-source-file="google-play-mini-icon.tsx" />
          <stop offset="1" stopColor="#C31162" data-sentry-element="stop" data-sentry-source-file="google-play-mini-icon.tsx" />
        </linearGradient>
        <linearGradient id="paint3_linear_565_984" x1="-3.56847" y1="42.4897" x2="9.63912" y2="28.9622" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="google-play-mini-icon.tsx">
          <stop offset="0" stopColor="#32A071" data-sentry-element="stop" data-sentry-source-file="google-play-mini-icon.tsx" />
          <stop offset="0.0685" stopColor="#2DA771" data-sentry-element="stop" data-sentry-source-file="google-play-mini-icon.tsx" />
          <stop offset="0.4762" stopColor="#15CF74" data-sentry-element="stop" data-sentry-source-file="google-play-mini-icon.tsx" />
          <stop offset="0.8009" stopColor="#06E775" data-sentry-element="stop" data-sentry-source-file="google-play-mini-icon.tsx" />
          <stop offset="1" stopColor="#00F076" data-sentry-element="stop" data-sentry-source-file="google-play-mini-icon.tsx" />
        </linearGradient>
        <clipPath id="clip0_565_984" data-sentry-element="clipPath" data-sentry-source-file="google-play-mini-icon.tsx">
          <rect width="31" height="34" fill="white" data-sentry-element="rect" data-sentry-source-file="google-play-mini-icon.tsx" />
        </clipPath>
      </defs>
    </svg>;
};