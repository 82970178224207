import { IIconComponent } from "@/services/types/types";
import { FC } from "react";
export const SoundIcon: FC<IIconComponent> = ({
  className,
  fill
}) => {
  return <svg width="24px" height="24px" viewBox="0 0 24 24" fill={fill} className={className} xmlns="http://www.w3.org/2000/svg" strokeWidth="2" data-sentry-element="svg" data-sentry-component="SoundIcon" data-sentry-source-file="sound-icon.tsx">
      <path d="M18.36,19.36a1,1,0,0,1-.7-.29,1,1,0,0,1,0-1.41,8,8,0,0,0,0-11.32,1,1,0,0,1,1.41-1.41,10,10,0,0,1,0,14.14A1,1,0,0,1,18.36,19.36Z" data-sentry-element="path" data-sentry-source-file="sound-icon.tsx">
      </path>
      <path d="M15.54,16.54a1,1,0,0,1-.71-.3,1,1,0,0,1,0-1.41,4,4,0,0,0,0-5.66,1,1,0,0,1,1.41-1.41,6,6,0,0,1,0,8.48A1,1,0,0,1,15.54,16.54Z" data-sentry-element="path" data-sentry-source-file="sound-icon.tsx">
      </path>
      <path d="M11.38,4.08a1,1,0,0,0-1.09.21L6.59,8H4a2,2,0,0,0-2,2v4a2,2,0,0,0,2,2H6.59l3.7,3.71A1,1,0,0,0,11,20a.84.84,0,0,0,.38-.08A1,1,0,0,0,12,19V5A1,1,0,0,0,11.38,4.08Z" data-sentry-element="path" data-sentry-source-file="sound-icon.tsx">
      </path>
    </svg>;
};