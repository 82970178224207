import { IIconComponent } from "../../../services/types/types";
import { FC } from "react";
export const OkColorIcon: FC<IIconComponent> = ({
  className,
  fill
}) => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill={fill ? fill : "none"} className={className} data-sentry-element="svg" data-sentry-component="OkColorIcon" data-sentry-source-file="ok-color-icon.tsx">
      <g clipPath="url(#clip0_143_6137)" data-sentry-element="g" data-sentry-source-file="ok-color-icon.tsx">
        <g clipPath="url(#clip1_143_6137)" data-sentry-element="g" data-sentry-source-file="ok-color-icon.tsx">
          <path d="M32 27.3173C32 29.9036 29.9141 32 27.3411 32H4.65885C2.08581 32 0 29.9036 0 27.3173V4.68275C0 2.09651 2.08581 0 4.65885 0H27.3411C29.9142 0 32 2.09651 32 4.68275V27.3173Z" fill="#FAAB62" data-sentry-element="path" data-sentry-source-file="ok-color-icon.tsx" />
          <path d="M31.8354 27.201C31.8354 29.7606 29.7709 31.8356 27.2243 31.8356H4.77501C2.22843 31.8356 0.164062 29.7606 0.164062 27.201V4.7989C0.164062 2.23926 2.22851 0.164307 4.77501 0.164307H27.2243C29.7709 0.164307 31.8354 2.23934 31.8354 4.7989V27.201Z" fill="#F7931E" data-sentry-element="path" data-sentry-source-file="ok-color-icon.tsx" />
          <path d="M15.9845 4.78247C12.8131 4.78247 10.2422 7.36664 10.2422 10.5543C10.2422 13.742 12.8131 16.3263 15.9845 16.3263C19.156 16.3263 21.7269 13.742 21.7269 10.5543C21.7269 7.36664 19.156 4.78247 15.9845 4.78247ZM15.9845 12.9404C14.6736 12.9404 13.6108 11.8721 13.6108 10.5544C13.6108 9.23667 14.6736 8.16842 15.9845 8.16842C17.2955 8.16842 18.3583 9.23667 18.3583 10.5544C18.3583 11.8721 17.2955 12.9404 15.9845 12.9404Z" fill="white" data-sentry-element="path" data-sentry-source-file="ok-color-icon.tsx" />
          <path d="M18.0131 20.919C20.312 20.4483 21.6896 19.354 21.7625 19.2953C22.4352 18.753 22.5432 17.7653 22.0037 17.0891C21.4643 16.4129 20.4817 16.3043 19.8089 16.8465C19.7947 16.8581 18.3253 17.991 15.9078 17.9926C13.4903 17.991 11.9899 16.8581 11.9757 16.8465C11.3028 16.3043 10.3203 16.4129 9.78084 17.0891C9.24135 17.7653 9.34941 18.753 10.0221 19.2953C10.096 19.3548 11.5305 20.4776 13.8938 20.9373L10.6001 24.3971C10.0015 25.0211 10.0195 26.0146 10.6402 26.6162C10.9432 26.9099 11.3337 27.0558 11.7239 27.0558C12.133 27.0558 12.5417 26.8952 12.848 26.5758L15.9079 23.2995L19.2768 26.597C19.8872 27.2095 20.8756 27.2085 21.4849 26.5953C22.0941 25.9819 22.0934 24.9882 21.4832 24.3758L18.0131 20.919Z" fill="white" data-sentry-element="path" data-sentry-source-file="ok-color-icon.tsx" />
        </g>
      </g>
      <defs data-sentry-element="defs" data-sentry-source-file="ok-color-icon.tsx">
        <clipPath id="clip0_143_6137" data-sentry-element="clipPath" data-sentry-source-file="ok-color-icon.tsx">
          <rect width="32" height="32" fill="white" data-sentry-element="rect" data-sentry-source-file="ok-color-icon.tsx" />
        </clipPath>
        <clipPath id="clip1_143_6137" data-sentry-element="clipPath" data-sentry-source-file="ok-color-icon.tsx">
          <rect width="32" height="32" fill="white" data-sentry-element="rect" data-sentry-source-file="ok-color-icon.tsx" />
        </clipPath>
      </defs>
    </svg>;
};