import { IIconComponent } from "../../../services/types/types";
import { FC } from "react";
export const YoutubeIcon: FC<IIconComponent> = ({
  className,
  fill
}) => {
  return <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} data-sentry-element="svg" data-sentry-component="YoutubeIcon" data-sentry-source-file="youtube-icon.tsx">
      <path fillRule="evenodd" clipRule="evenodd" d="M21.5415 6.34206C21.2895 5.42157 20.544 4.6955 19.5956 4.44664C17.8807 4 11 4 11 4C11 4 4.12284 4 2.40441 4.44664C1.4595 4.69207 0.714016 5.41822 0.458526 6.34206C-4.31247e-08 8.0125 0 11.5 0 11.5C0 11.5 -4.31247e-08 14.9875 0.458526 16.6579C0.710488 17.5784 1.45598 18.3045 2.40441 18.5534C4.12284 19 11 19 11 19C11 19 17.8807 19 19.5956 18.5534C20.5406 18.3079 21.286 17.5818 21.5415 16.6579C22 14.9875 22 11.5 22 11.5C22 11.5 22 8.0125 21.5415 6.34206Z" fill={fill ? fill : "#C9CED6"} data-sentry-element="path" data-sentry-source-file="youtube-icon.tsx" />
      <path fillRule="evenodd" clipRule="evenodd" d="M9.00055 15.001L15.0005 11.5009L9.00055 8.00098V15.001Z" fill={fill ? "#FFFFFF" : "#1D1D1D"} data-sentry-element="path" data-sentry-source-file="youtube-icon.tsx" />
    </svg>;
};