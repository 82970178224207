import { IIconComponent } from "@/services/types/types";
import { FC } from "react";
export const SaleIcon: FC<IIconComponent> = ({
  className
}) => {
  return <svg className={className} width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="SaleIcon" data-sentry-source-file="sale-icon.tsx">
      <circle cx="10" cy="9.5" r="1.75" stroke="#1D1D1D" strokeWidth="1.5" data-sentry-element="circle" data-sentry-source-file="sale-icon.tsx" />
      <circle cx="16" cy="15.5" r="1.75" stroke="#1D1D1D" strokeWidth="1.5" data-sentry-element="circle" data-sentry-source-file="sale-icon.tsx" />
      <path d="M22.0688 11.925L22.8766 12.1892L22.0688 11.925C21.9466 12.2986 21.9466 12.7014 22.0688 13.075L22.8766 12.8108L22.0688 13.075L22.9025 15.6246C22.9228 15.6865 22.9007 15.7545 22.8479 15.7927L20.6748 17.3653C20.3563 17.5957 20.1195 17.9216 19.9988 18.2957L19.1747 20.8484C19.1547 20.9104 19.0969 20.9524 19.0317 20.9523L16.3493 20.9472C15.9562 20.9465 15.5731 21.0709 15.2555 21.3026L15.7564 21.9893L15.2555 21.3026L13.0884 22.8834C13.0357 22.9218 12.9643 22.9218 12.9116 22.8834L10.7445 21.3026L10.2436 21.9893L10.7445 21.3026C10.4269 21.0709 10.0438 20.9465 9.65072 20.9472L6.9683 20.9523C6.90311 20.9524 6.8453 20.9104 6.82527 20.8484L6.00122 18.2957C5.88046 17.9216 5.64369 17.5957 5.32523 17.3653L3.15211 15.7927L2.65381 16.4813L3.15211 15.7927C3.0993 15.7545 3.07722 15.6865 3.09748 15.6246L3.93125 13.075C4.05343 12.7014 4.05343 12.2986 3.93125 11.925L3.09748 9.37542C3.07722 9.31346 3.0993 9.2455 3.15211 9.20728L5.32523 7.63473C5.64369 7.40428 5.88046 7.07839 6.00122 6.70431L6.82527 4.15161C6.8453 4.08957 6.90311 4.04757 6.96831 4.04769L9.65071 4.0528C10.0438 4.05355 10.4269 3.92907 10.7445 3.69741L12.9116 2.1166C12.9643 2.07818 13.0357 2.07818 13.0884 2.1166L15.2555 3.69741C15.5731 3.92907 15.9562 4.05355 16.3493 4.0528L19.0317 4.04769C19.0969 4.04757 19.1547 4.08957 19.1747 4.15161L19.9988 6.70431C20.1195 7.07839 20.3563 7.40428 20.6748 7.63473L22.8479 9.20728C22.9007 9.2455 22.9228 9.31346 22.9025 9.37543L22.0688 11.925Z" stroke="#1D1D1D" strokeWidth="1.7" data-sentry-element="path" data-sentry-source-file="sale-icon.tsx" />
      <path d="M9.5 16L16.5 9" stroke="#1D1D1D" strokeWidth="2" strokeLinecap="round" data-sentry-element="path" data-sentry-source-file="sale-icon.tsx" />
    </svg>;
};