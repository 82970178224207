import { IIconComponent } from "@/services/types/types";
import { FC } from "react";
export const AppleIcon: FC<IIconComponent> = ({
  className
}) => {
  return <svg width="96" height="25" viewBox="0 0 96 25" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} data-sentry-element="svg" data-sentry-component="AppleIcon" data-sentry-source-file="apple-icon.tsx">
      <path d="M17.4384 12.4063C17.4175 10.0038 19.4738 8.83606 19.5641 8.78238C18.397 7.14488 16.5908 6.91671 15.9587 6.89657C14.4443 6.74222 12.9716 7.77572 12.2005 7.77572C11.4155 7.77572 10.2207 6.91671 8.94245 6.93684C7.29607 6.96369 5.74694 7.8831 4.90638 9.31926C3.16274 12.2386 4.46179 16.5336 6.13596 18.8959C6.97652 20.0502 7.94907 21.3454 9.23422 21.2985C10.4846 21.2515 10.957 20.5267 12.4645 20.5267C13.965 20.5267 14.4026 21.2985 15.7086 21.2716C17.0563 21.2515 17.8968 20.1106 18.7096 18.9429C19.6752 17.6208 20.0642 16.3122 20.0781 16.245C20.0434 16.2316 17.4661 15.2787 17.4384 12.4063Z" fill="#F7F8F9" data-sentry-element="path" data-sentry-source-file="apple-icon.tsx" />
      <path d="M14.9727 5.34564C15.6466 4.5336 16.105 3.42628 15.98 2.29883C15.0075 2.33909 13.7848 2.9498 13.0832 3.74841C12.4649 4.45307 11.9092 5.60066 12.0481 6.68114C13.1457 6.76167 14.2711 6.14425 14.9727 5.34564Z" fill="#F7F8F9" data-sentry-element="path" data-sentry-source-file="apple-icon.tsx" />
      <path d="M41.1481 15.8964L40.6581 14.3564H37.0181L36.5281 15.8964H34.0781L37.4101 6.09639H40.2661L43.5981 15.8964H41.1481ZM37.6901 12.2564H39.9861L38.8381 8.65839L37.6901 12.2564Z" fill="white" data-sentry-element="path" data-sentry-source-file="apple-icon.tsx" />
      <path d="M48.5498 8.70039C49.4738 8.70039 50.2624 9.05972 50.9158 9.77839C51.5691 10.4877 51.8958 11.3604 51.8958 12.3964C51.8958 13.4324 51.5691 14.3097 50.9158 15.0284C50.2624 15.7377 49.4738 16.0924 48.5498 16.0924C47.6538 16.0924 46.9584 15.8077 46.4637 15.2384V18.6964H44.3638V8.89639H46.4637V9.55439C46.9584 8.98506 47.6538 8.70039 48.5498 8.70039ZM46.9257 13.6424C47.2431 13.9504 47.6444 14.1044 48.1298 14.1044C48.6151 14.1044 49.0118 13.9504 49.3197 13.6424C49.6371 13.3344 49.7958 12.9191 49.7958 12.3964C49.7958 11.8737 49.6371 11.4584 49.3197 11.1504C49.0118 10.8424 48.6151 10.6884 48.1298 10.6884C47.6444 10.6884 47.2431 10.8424 46.9257 11.1504C46.6178 11.4584 46.4637 11.8737 46.4637 12.3964C46.4637 12.9191 46.6178 13.3344 46.9257 13.6424Z" fill="white" data-sentry-element="path" data-sentry-source-file="apple-icon.tsx" />
      <path d="M57.2724 8.70039C58.1964 8.70039 58.9851 9.05972 59.6384 9.77839C60.2917 10.4877 60.6184 11.3604 60.6184 12.3964C60.6184 13.4324 60.2917 14.3097 59.6384 15.0284C58.9851 15.7377 58.1964 16.0924 57.2724 16.0924C56.3764 16.0924 55.6811 15.8077 55.1864 15.2384V18.6964H53.0864V8.89639H55.1864V9.55439C55.6811 8.98506 56.3764 8.70039 57.2724 8.70039ZM55.6484 13.6424C55.9657 13.9504 56.3671 14.1044 56.8524 14.1044C57.3377 14.1044 57.7344 13.9504 58.0424 13.6424C58.3597 13.3344 58.5184 12.9191 58.5184 12.3964C58.5184 11.8737 58.3597 11.4584 58.0424 11.1504C57.7344 10.8424 57.3377 10.6884 56.8524 10.6884C56.3671 10.6884 55.9657 10.8424 55.6484 11.1504C55.3404 11.4584 55.1864 11.8737 55.1864 12.3964C55.1864 12.9191 55.3404 13.3344 55.6484 13.6424Z" fill="white" data-sentry-element="path" data-sentry-source-file="apple-icon.tsx" />
      <path d="M65.3091 16.0924C64.3104 16.0924 63.4751 15.8777 62.8031 15.4484C62.1404 15.0097 61.6691 14.4171 61.3891 13.6704L63.3211 12.5504C63.7131 13.4557 64.3991 13.9084 65.3791 13.9084C66.2657 13.9084 66.7091 13.6424 66.7091 13.1104C66.7091 12.8211 66.5644 12.5971 66.2751 12.4384C65.9951 12.2704 65.4631 12.0791 64.6791 11.8644C64.2684 11.7524 63.9091 11.6264 63.6011 11.4864C63.2931 11.3464 62.9897 11.1644 62.6911 10.9404C62.4017 10.7071 62.1777 10.4177 62.0191 10.0724C61.8697 9.72706 61.7951 9.33039 61.7951 8.88239C61.7951 7.97706 62.1171 7.25372 62.7611 6.71239C63.4144 6.17106 64.1891 5.90039 65.0851 5.90039C65.8877 5.90039 66.5971 6.09172 67.2131 6.47439C67.8291 6.84772 68.3144 7.39372 68.6691 8.11239L66.7791 9.21839C66.6017 8.84506 66.3824 8.56506 66.1211 8.37839C65.8597 8.18239 65.5144 8.08439 65.0851 8.08439C64.7491 8.08439 64.4877 8.15906 64.3011 8.30839C64.1237 8.44839 64.0351 8.62106 64.0351 8.82639C64.0351 9.06906 64.1471 9.27906 64.3711 9.45639C64.6044 9.63372 65.0804 9.82972 65.7991 10.0444C66.1911 10.1657 66.4944 10.2637 66.7091 10.3384C66.9237 10.4131 67.1944 10.5391 67.5211 10.7164C67.8571 10.8844 68.1091 11.0664 68.2771 11.2624C68.4544 11.4491 68.6084 11.7011 68.7391 12.0184C68.8791 12.3264 68.9491 12.6811 68.9491 13.0824C68.9491 14.0157 68.6131 14.7531 67.9411 15.2944C67.2691 15.8264 66.3917 16.0924 65.3091 16.0924Z" fill="white" data-sentry-element="path" data-sentry-source-file="apple-icon.tsx" />
      <path d="M73.8954 10.9124H72.4534V13.3904C72.4534 13.6704 72.5654 13.8477 72.7894 13.9224C73.0134 13.9971 73.3821 14.0204 73.8954 13.9924V15.8964C72.5608 16.0364 71.6368 15.9104 71.1234 15.5184C70.6101 15.1171 70.3534 14.4077 70.3534 13.3904V10.9124H69.2334V8.89639H70.3534V7.56639L72.4534 6.93639V8.89639H73.8954V10.9124Z" fill="white" data-sentry-element="path" data-sentry-source-file="apple-icon.tsx" />
      <path d="M80.9397 15.0284C80.2211 15.7377 79.3437 16.0924 78.3077 16.0924C77.2717 16.0924 76.3944 15.7377 75.6757 15.0284C74.9571 14.3097 74.5977 13.4324 74.5977 12.3964C74.5977 11.3604 74.9571 10.4877 75.6757 9.77839C76.3944 9.05972 77.2717 8.70039 78.3077 8.70039C79.3437 8.70039 80.2211 9.05972 80.9397 9.77839C81.6584 10.4877 82.0177 11.3604 82.0177 12.3964C82.0177 13.4324 81.6584 14.3097 80.9397 15.0284ZM77.1597 13.5864C77.4677 13.8944 77.8504 14.0484 78.3077 14.0484C78.7651 14.0484 79.1477 13.8944 79.4557 13.5864C79.7637 13.2784 79.9177 12.8817 79.9177 12.3964C79.9177 11.9111 79.7637 11.5144 79.4557 11.2064C79.1477 10.8984 78.7651 10.7444 78.3077 10.7444C77.8504 10.7444 77.4677 10.8984 77.1597 11.2064C76.8517 11.5144 76.6977 11.9111 76.6977 12.3964C76.6977 12.8817 76.8517 13.2784 77.1597 13.5864Z" fill="white" data-sentry-element="path" data-sentry-source-file="apple-icon.tsx" />
      <path d="M85.3055 10.1424C85.4362 9.69439 85.6975 9.35372 86.0895 9.12039C86.4909 8.87772 86.9295 8.75639 87.4055 8.75639V11.1364C86.8922 11.0524 86.4115 11.1411 85.9635 11.4024C85.5249 11.6544 85.3055 12.0977 85.3055 12.7324V15.8964H83.2055V8.89639H85.3055V10.1424Z" fill="white" data-sentry-element="path" data-sentry-source-file="apple-icon.tsx" />
      <path d="M90.0581 13.2364C90.3008 13.8897 90.8608 14.2164 91.7381 14.2164C92.3074 14.2164 92.7554 14.0391 93.0821 13.6844L94.7621 14.6504C94.0714 15.6117 93.0541 16.0924 91.7101 16.0924C90.5341 16.0924 89.5914 15.7424 88.8821 15.0424C88.1821 14.3424 87.8321 13.4604 87.8321 12.3964C87.8321 11.3417 88.1774 10.4644 88.8681 9.76439C89.5681 9.05506 90.4641 8.70039 91.5561 8.70039C92.5734 8.70039 93.4181 9.05506 94.0901 9.76439C94.7714 10.4644 95.1121 11.3417 95.1121 12.3964C95.1121 12.6951 95.0841 12.9751 95.0281 13.2364H90.0581ZM90.0161 11.6684H93.0261C92.8208 10.9311 92.3261 10.5624 91.5421 10.5624C90.7301 10.5624 90.2214 10.9311 90.0161 11.6684Z" fill="white" data-sentry-element="path" data-sentry-source-file="apple-icon.tsx" />
    </svg>;
};