import { IIconComponent } from "@/services/types/types";
import { FC } from "react";
export const CatalogIcon: FC<IIconComponent> = ({
  className
}) => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className={className} data-sentry-element="svg" data-sentry-component="CatalogIcon" data-sentry-source-file="catalog-icon.tsx">
      <rect x="4" y="4" width="6" height="6" rx="1" stroke="#1D1D1D" strokeWidth="2" data-sentry-element="rect" data-sentry-source-file="catalog-icon.tsx" />
      <rect x="14" y="4" width="6" height="6" rx="1" stroke="#1D1D1D" strokeWidth="2" data-sentry-element="rect" data-sentry-source-file="catalog-icon.tsx" />
      <rect x="4" y="14" width="6" height="6" rx="1" stroke="#1D1D1D" strokeWidth="2" data-sentry-element="rect" data-sentry-source-file="catalog-icon.tsx" />
      <rect x="14" y="14" width="6" height="6" rx="1" stroke="#1D1D1D" strokeWidth="2" data-sentry-element="rect" data-sentry-source-file="catalog-icon.tsx" />
    </svg>;
};