import { IIconComponent } from "../../../services/types/types";
import { FC } from "react";
export const MastercardIcon: FC<IIconComponent> = ({
  className,
  fill
}) => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="64" height="10" viewBox="0 0 64 10" fill="none" className={className} data-sentry-element="svg" data-sentry-component="MastercardIcon" data-sentry-source-file="mastercard-icon.tsx">
      <path fillRule="evenodd" clipRule="evenodd" d="M51.0936 9.61567L52.4733 2.54293H54.2179L54.4005 3.24013C54.7122 2.89117 55.3121 2.54293 56.3266 2.54293L55.7799 4.38554C55.2856 4.38554 54.3481 4.26141 53.7497 6.37792L53.1245 9.61567H51.0936ZM24.3571 0.746094H26.3677L25.955 2.61983H27.0373L26.6767 4.14826H25.6457L25.0787 7.00775C24.9753 7.55042 25.0274 8.0433 26.1874 7.87083L26.0584 9.30039C25.62 9.57136 25.0274 9.6457 24.5634 9.6457C24.0998 9.6457 22.5784 9.37399 22.9658 7.45155L24.3571 0.746094ZM33.0697 6.46543C33.3013 5.33101 33.843 2.52096 30.3117 2.52096C27.0637 2.52096 26.6514 5.60309 26.5741 6.04616C26.4971 6.49034 26.4451 9.6208 29.9247 9.6208C30.7753 9.6457 32.0126 9.54719 32.5027 9.30039L32.8116 7.84629C32.5027 7.9199 31.3427 8.01876 30.5433 8.01876C29.7443 8.01876 28.4561 8.0682 28.6873 6.46543H33.0697Z" fill={fill ? fill : "#F7F8F9"} data-sentry-element="path" data-sentry-source-file="mastercard-icon.tsx" />
      <path fillRule="evenodd" clipRule="evenodd" d="M28.8164 5.18433H31.3167C31.4457 4.49372 31.2137 3.90234 30.4147 3.90234C29.6154 3.90198 29.0488 4.29672 28.8164 5.18433Z" fill="#1D1D1D" data-sentry-element="path" data-sentry-source-file="mastercard-icon.tsx" />
      <path fillRule="evenodd" clipRule="evenodd" d="M22.4503 4.19846L22.9139 2.76853C21.9599 2.42359 21.213 2.49719 20.9289 2.49719C20.6456 2.49719 18.2318 2.46899 17.862 4.17392C17.5783 5.48045 18.0787 6.02166 18.5576 6.21976C19.3309 6.54017 19.7697 6.83641 20.182 7.03378C20.6919 7.27765 20.5985 8.09386 19.9239 8.09386C19.6142 8.09386 18.2739 8.2418 17.269 7.92139L16.9857 9.37549C17.9137 9.62229 18.6606 9.62229 19.357 9.62229C19.8462 9.62229 22.2436 9.74606 22.5785 7.62552C22.7424 6.59143 22.2692 6.12163 21.8829 5.89972C21.4966 5.65292 20.8519 5.30834 20.4392 5.13514C20.0269 4.96304 19.7176 4.29732 20.4653 4.10032C20.9293 4.05015 21.8829 4.05015 22.4503 4.19846ZM9.4082 9.62193L11.0835 1.0918H7.78459L5.33521 6.2692L4.94893 1.0918H1.72697L0 9.62193H2.01027L3.24722 3.45878L3.78893 9.62193H5.6966L8.50624 3.60671L7.32021 9.62193H9.4082ZM11.6244 2.74399L11.3147 4.27206C11.8051 4.22299 13.1194 4.02599 13.815 4.05015C14.5114 4.07505 15.2844 3.97619 15.0524 5.03591C14.5367 5.08607 10.9281 4.54303 10.3615 7.50102C10.1295 8.95658 11.4434 9.6959 12.4747 9.64646C13.5061 9.59703 13.7894 9.47326 14.1504 9.22719L14.4084 9.6212H16.2897L17.089 5.77559C17.2954 4.81437 17.6296 2.49646 14.2534 2.49646C13.6351 2.52209 12.9395 2.49719 11.6244 2.74399Z" fill={fill ? fill : "#F7F8F9"} data-sentry-element="path" data-sentry-source-file="mastercard-icon.tsx" />
      <path fillRule="evenodd" clipRule="evenodd" d="M14.7176 6.46661C14.3826 6.41717 13.635 6.2198 12.9903 6.63908C12.3456 7.05835 12.1656 7.79767 12.836 8.04447C13.4811 8.28175 14.4332 7.87273 14.563 7.23119L14.7176 6.46661Z" fill="#1D1D1D" data-sentry-element="path" data-sentry-source-file="mastercard-icon.tsx" />
      <path fillRule="evenodd" clipRule="evenodd" d="M44.2043 3.06375C43.6882 2.76788 43.3279 2.71918 42.658 2.71918C41.988 2.71918 40.4927 3.3318 40.2094 5.06089C39.8744 7.1071 41.3437 8.11775 43.4053 7.4524L43.9983 9.1782C43.3283 9.47371 42.6584 9.62164 41.5497 9.62164C39.4621 9.62164 37.1941 7.84714 38.2243 4.24724C38.9847 1.59464 41.3701 0.623172 42.761 0.67224C44.4106 0.730829 45.004 1.26398 45.5193 1.80666L44.2043 3.06375ZM61.3454 3.35999C60.9579 3.08865 60.7263 2.52181 59.7731 2.52181C58.6647 2.52181 56.9378 2.32444 55.9581 5.52923C55.3038 7.67211 56.6281 9.62164 58.0713 9.62164C58.7421 9.59748 59.412 9.32614 59.824 9.05517L60.0828 9.62164H62.2473L63.9999 0.648438H61.8863L61.3454 3.35999Z" fill={fill ? fill : "#F7F8F9"} data-sentry-element="path" data-sentry-source-file="mastercard-icon.tsx" />
      <path fillRule="evenodd" clipRule="evenodd" d="M60.6233 6.24544C60.8296 5.60463 60.7167 4.80379 60.4939 4.51964C59.8756 3.73089 58.4533 4.35449 58.0196 5.90013C57.7363 6.91078 58.3546 7.67536 59.0506 7.67536C59.7986 7.67536 60.2619 7.10815 60.6233 6.24544Z" fill="#1D1D1D" data-sentry-element="path" data-sentry-source-file="mastercard-icon.tsx" />
      <path fillRule="evenodd" clipRule="evenodd" d="M45.647 2.74363L45.3381 4.27169C45.8277 4.22263 47.1424 4.02562 47.8376 4.04979C48.5344 4.07469 49.3077 3.97582 49.0749 5.03554C48.5597 5.08571 44.9514 4.54267 44.3844 7.50066C44.1521 8.95621 45.4663 9.69553 46.4977 9.6461C47.5291 9.59666 47.8124 9.47289 48.1734 9.22682L48.4314 9.62083H50.3119L51.112 5.77522C51.3176 4.814 51.6526 2.49609 48.276 2.49609C47.6577 2.52173 46.9621 2.49682 45.647 2.74363Z" fill={fill ? fill : "#F7F8F9"} data-sentry-element="path" data-sentry-source-file="mastercard-icon.tsx" />
      <path fillRule="evenodd" clipRule="evenodd" d="M48.741 6.46661C48.406 6.41717 47.6583 6.2198 47.0136 6.63908C46.3689 7.05835 46.189 7.79767 46.8597 8.04447C47.5052 8.28175 48.4569 7.87273 48.5863 7.23119L48.741 6.46661Z" fill="#1D1D1D" data-sentry-element="path" data-sentry-source-file="mastercard-icon.tsx" />
      <path fillRule="evenodd" clipRule="evenodd" d="M33.1279 9.62047L34.4939 2.61914H36.2212L36.4016 3.30939C36.7105 2.96372 37.3031 2.61914 38.3081 2.61914L37.7928 4.46798C37.3031 4.46798 36.3491 4.32041 35.7569 6.41568L35.1386 9.62047H33.1279Z" fill={fill ? fill : "#F7F8F9"} data-sentry-element="path" data-sentry-source-file="mastercard-icon.tsx" />
    </svg>;
};