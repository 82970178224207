import { IIconComponent } from "@/services/types/types";
import { FC } from "react";
export const CityIcon: FC<IIconComponent> = ({
  className
}) => {
  return <svg width="17" height="24" viewBox="0 0 17 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} data-sentry-element="svg" data-sentry-component="CityIcon" data-sentry-source-file="city-icon.tsx">
      <path d="M8.5 0C3.8131 0 0 3.74234 0 8.34248C0 12.5891 4.59055 19.0449 7.02791 22.1733C7.78665 23.1471 9.21817 23.137 9.96641 22.155C12.3938 18.9695 17 12.3913 17 8.34248C17 3.74234 13.1869 0 8.5 0Z" fill="#82C21D" data-sentry-element="path" data-sentry-source-file="city-icon.tsx" />
      <circle cx="8.5" cy="8.5" r="6.5" fill="white" data-sentry-element="circle" data-sentry-source-file="city-icon.tsx" />
      <path d="M6.95312 9.25195C6.95312 8.97581 7.17698 8.75195 7.45312 8.75195H9.54403C9.82018 8.75195 10.044 8.97581 10.044 9.25195V11.3429C10.044 11.619 9.82018 11.8429 9.54403 11.8429H7.45312C7.17698 11.8429 6.95312 11.619 6.95312 11.3429V9.25195Z" stroke="#82C21D" data-sentry-element="path" data-sentry-source-file="city-icon.tsx" />
      <path d="M4.5 6.87796C4.5 6.71078 4.58355 6.55466 4.72265 6.46193L4.4453 6.04591L4.72265 6.46193L8.22265 4.1286C8.3906 4.01663 8.6094 4.01663 8.77735 4.1286L12.2773 6.46193C12.4164 6.55467 12.5 6.71078 12.5 6.87796V11.3428C12.5 11.6189 12.2761 11.8428 12 11.8428H9.4H7.6H5C4.72386 11.8428 4.5 11.6189 4.5 11.3428V6.87796Z" stroke="#82C21D" data-sentry-element="path" data-sentry-source-file="city-icon.tsx" />
    </svg>;
};