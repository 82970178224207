import { IIconComponent } from "@/services/types/types";
import { FC } from "react";
export const GoldWarrantyIcon: FC<IIconComponent> = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="129" height="56" viewBox="0 0 129 56" fill="none" data-sentry-element="svg" data-sentry-component="GoldWarrantyIcon" data-sentry-source-file="gold-warranty-icon.tsx">
      <path d="M23.9375 18.0817C23.9375 9.56433 30.8752 2.65967 39.4333 2.65967H113.504C122.062 2.65967 128.999 9.56433 128.999 18.0817V38.4387C128.999 46.956 122.062 53.8607 113.504 53.8607H39.4334C30.8752 53.8607 23.9375 46.956 23.9375 38.4387V18.0817Z" fill="white" data-sentry-element="path" data-sentry-source-file="gold-warranty-icon.tsx" />
      <path fillRule="evenodd" clipRule="evenodd" d="M113.504 5.74407H39.4333C32.5869 5.74407 27.0367 11.2678 27.0367 18.0817V38.4387C27.0367 45.2526 32.5869 50.7763 39.4334 50.7763H113.504C120.35 50.7763 125.9 45.2526 125.9 38.4387V18.0817C125.9 11.2678 120.35 5.74407 113.504 5.74407ZM39.4333 2.65967C30.8752 2.65967 23.9375 9.56433 23.9375 18.0817V38.4387C23.9375 46.956 30.8752 53.8607 39.4334 53.8607H113.504C122.062 53.8607 128.999 46.956 128.999 38.4387V18.0817C128.999 9.56433 122.062 2.65967 113.504 2.65967H39.4333Z" fill="#1D1D1D" data-sentry-element="path" data-sentry-source-file="gold-warranty-icon.tsx" />
      <path d="M23.9375 2.65967H113.504C122.062 2.65967 128.999 9.5831 128.999 18.1236V29.2576H23.9375V2.65967Z" fill="#1D1D1D" data-sentry-element="path" data-sentry-source-file="gold-warranty-icon.tsx" />
      <path d="M55.8557 27.9278C55.8557 43.352 43.352 55.8557 27.9278 55.8557C12.5037 55.8557 0 43.352 0 27.9278C0 12.5037 12.5037 0 27.9278 0C43.352 0 55.8557 12.5037 55.8557 27.9278Z" fill="#1D1D1D" data-sentry-element="path" data-sentry-source-file="gold-warranty-icon.tsx" />
      <path fillRule="evenodd" clipRule="evenodd" d="M27.9278 52.132C41.2954 52.132 52.132 41.2954 52.132 27.9278C52.132 14.5603 41.2954 3.72371 27.9278 3.72371C14.5603 3.72371 3.72371 14.5603 3.72371 27.9278C3.72371 41.2954 14.5603 52.132 27.9278 52.132ZM27.9278 55.8557C43.352 55.8557 55.8557 43.352 55.8557 27.9278C55.8557 12.5037 43.352 0 27.9278 0C12.5037 0 0 12.5037 0 27.9278C0 43.352 12.5037 55.8557 27.9278 55.8557Z" fill="white" data-sentry-element="path" data-sentry-source-file="gold-warranty-icon.tsx" />
      <path d="M80.9009 10.2915V21.6258H77.4382V13.5558H74.9451V16.9334C74.9451 18.6562 74.5065 19.9181 73.6293 20.7191C72.7674 21.52 71.4978 21.8223 69.8203 21.6258V18.4522C70.359 18.6033 70.7668 18.5278 71.0438 18.2255C71.3362 17.9233 71.4824 17.417 71.4824 16.7067V10.2915H80.9009Z" fill="white" data-sentry-element="path" data-sentry-source-file="gold-warranty-icon.tsx" />
      <path d="M86.5317 17.3188C86.9318 18.3767 87.8552 18.9056 89.3018 18.9056C90.2406 18.9056 90.9793 18.6184 91.5179 18.0442L94.2881 19.6083C93.1492 21.1649 91.4718 21.9432 89.2557 21.9432C87.3166 21.9432 85.7622 21.3765 84.5926 20.243C83.4384 19.1096 82.8613 17.6815 82.8613 15.9587C82.8613 14.2509 83.4307 12.8304 84.5695 11.6969C85.7237 10.5484 87.2011 9.97412 89.0017 9.97412C90.6792 9.97412 92.072 10.5484 93.18 11.6969C94.3035 12.8304 94.8652 14.2509 94.8652 15.9587C94.8652 16.4423 94.819 16.8956 94.7267 17.3188H86.5317ZM86.4624 14.7799H91.4256C91.087 13.586 90.2714 12.9891 88.9786 12.9891C87.6397 12.9891 86.801 13.586 86.4624 14.7799Z" fill="white" data-sentry-element="path" data-sentry-source-file="gold-warranty-icon.tsx" />
      <path d="M105.728 10.2915V13.5558H102.288V21.6258H98.8253V13.5558H95.3395V10.2915H105.728Z" fill="white" data-sentry-element="path" data-sentry-source-file="gold-warranty-icon.tsx" />
      <path d="M60.9745 36.1158V38.2616H58.0493V43.5662H55.8555V36.1158H60.9745Z" fill="#1D1D1D" data-sentry-element="path" data-sentry-source-file="gold-warranty-icon.tsx" />
      <path d="M66.9493 36.1158H69.1432V43.5662H66.9493V42.8659C66.4325 43.4719 65.7061 43.7749 64.77 43.7749C63.8047 43.7749 62.9808 43.3974 62.2983 42.6424C61.6157 41.8775 61.2745 40.9437 61.2745 39.841C61.2745 38.7384 61.6157 37.8096 62.2983 37.0546C62.9808 36.2897 63.8047 35.9072 64.77 35.9072C65.7061 35.9072 66.4325 36.2102 66.9493 36.8162V36.1158ZM63.951 41.1672C64.2728 41.495 64.692 41.6589 65.2088 41.6589C65.7256 41.6589 66.1449 41.495 66.4666 41.1672C66.7884 40.8394 66.9493 40.3973 66.9493 39.841C66.9493 39.2847 66.7884 38.8427 66.4666 38.5149C66.1449 38.1871 65.7256 38.0231 65.2088 38.0231C64.692 38.0231 64.2728 38.1871 63.951 38.5149C63.6292 38.8427 63.4683 39.2847 63.4683 39.841C63.4683 40.3973 63.6292 40.8394 63.951 41.1672Z" fill="#1D1D1D" data-sentry-element="path" data-sentry-source-file="gold-warranty-icon.tsx" />
      <path d="M75.1258 35.9072C76.0912 35.9072 76.9151 36.2897 77.5976 37.0546C78.2802 37.8096 78.6214 38.7384 78.6214 39.841C78.6214 40.9437 78.2802 41.8775 77.5976 42.6424C76.9151 43.3974 76.0912 43.7749 75.1258 43.7749C74.1898 43.7749 73.4634 43.4719 72.9466 42.8659V46.5464H70.7527V36.1158H72.9466V36.8162C73.4634 36.2102 74.1898 35.9072 75.1258 35.9072ZM73.4292 41.1672C73.7608 41.495 74.18 41.6589 74.6871 41.6589C75.1941 41.6589 75.6085 41.495 75.9303 41.1672C76.2618 40.8394 76.4276 40.3973 76.4276 39.841C76.4276 39.2847 76.2618 38.8427 75.9303 38.5149C75.6085 38.1871 75.1941 38.0231 74.6871 38.0231C74.18 38.0231 73.7608 38.1871 73.4292 38.5149C73.1075 38.8427 72.9466 39.2847 72.9466 39.841C72.9466 40.3973 73.1075 40.8394 73.4292 41.1672Z" fill="#1D1D1D" data-sentry-element="path" data-sentry-source-file="gold-warranty-icon.tsx" />
      <path d="M85.1745 36.1158H87.3684V43.5662H85.1745V42.8659C84.6577 43.4719 83.9313 43.7749 82.9952 43.7749C82.0299 43.7749 81.206 43.3974 80.5235 42.6424C79.8409 41.8775 79.4997 40.9437 79.4997 39.841C79.4997 38.7384 79.8409 37.8096 80.5235 37.0546C81.206 36.2897 82.0299 35.9072 82.9952 35.9072C83.9313 35.9072 84.6577 36.2102 85.1745 36.8162V36.1158ZM82.1762 41.1672C82.498 41.495 82.9172 41.6589 83.434 41.6589C83.9508 41.6589 84.3701 41.495 84.6918 41.1672C85.0136 40.8394 85.1745 40.3973 85.1745 39.841C85.1745 39.2847 85.0136 38.8427 84.6918 38.5149C84.3701 38.1871 83.9508 38.0231 83.434 38.0231C82.9172 38.0231 82.498 38.1871 82.1762 38.5149C81.8544 38.8427 81.6935 39.2847 81.6935 39.841C81.6935 40.3973 81.8544 40.8394 82.1762 41.1672Z" fill="#1D1D1D" data-sentry-element="path" data-sentry-source-file="gold-warranty-icon.tsx" />
      <path d="M93.6582 36.1158H95.8521V43.5662H93.6582V40.8841H91.1718V43.5662H88.9779V36.1158H91.1718V38.798H93.6582V36.1158Z" fill="#1D1D1D" data-sentry-element="path" data-sentry-source-file="gold-warranty-icon.tsx" />
      <path d="M103.386 36.1158V38.2616H101.206V43.5662H99.0124V38.2616H96.8039V36.1158H103.386Z" fill="#1D1D1D" data-sentry-element="path" data-sentry-source-file="gold-warranty-icon.tsx" />
      <path d="M109.598 36.1158H111.206V43.5662H109.071V39.841L105.941 43.5662H104.332V36.1158H106.468V39.841L109.598 36.1158Z" fill="#1D1D1D" data-sentry-element="path" data-sentry-source-file="gold-warranty-icon.tsx" />
      <path d="M118.082 36.1158H119.691V43.5662H117.555V39.841L114.425 43.5662H112.816V36.1158H114.952V39.841L118.082 36.1158Z" fill="#1D1D1D" data-sentry-element="path" data-sentry-source-file="gold-warranty-icon.tsx" />
      <path d="M28.4549 24.7093C31.3482 24.7093 33.7499 25.5562 35.6601 27.2499C37.5983 28.9163 38.5674 31.2657 38.5674 34.298C38.5674 37.303 37.5281 39.6523 35.4494 41.346C33.3707 43.0398 30.8286 43.8866 27.8229 43.8866C25.379 43.8866 23.2301 43.3813 21.3762 42.3705C19.5222 41.3324 18.1599 39.7889 17.2891 37.7401L23.1037 34.4619C23.8341 36.4834 25.4071 37.4942 27.8229 37.4942C29.1431 37.4942 30.1403 37.1937 30.8145 36.5927C31.4887 35.9917 31.8258 35.2268 31.8258 34.298C31.8258 33.3692 31.5027 32.6042 30.8566 32.0032C30.2106 31.4022 29.2695 31.1017 28.0336 31.1017H18.8059L20.0279 14.6289H37.0927V20.7755H26.3482L26.0532 24.7093H28.4549Z" fill="white" data-sentry-element="path" data-sentry-source-file="gold-warranty-icon.tsx" />
    </svg>;
};