import { IIconComponent } from "@/services/types/types";
import { FC } from "react";
export const QuestionGreenIcon: FC<IIconComponent> = ({
  className
}) => {
  return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="QuestionGreenIcon" data-sentry-source-file="question-green-icon.tsx">
      <g clipPath="url(#clip0_27338_71435)" data-sentry-element="g" data-sentry-source-file="question-green-icon.tsx">
        <path d="M8.0004 0.734375C3.9869 0.734375 0.733398 3.98787 0.733398 8.00137C0.733398 12.0149 3.9869 15.2684 8.0004 15.2684C12.0139 15.2684 15.2674 12.0149 15.2674 8.00137C15.2674 3.98787 12.0139 0.734375 8.0004 0.734375ZM8.6644 12.1869H7.3104V10.8894H8.6644V12.1869ZM8.6644 9.50337V9.93237H7.3104V9.40437C7.3104 7.80937 9.1254 7.55638 9.1254 6.42287C9.1254 5.90637 8.6639 5.51037 8.0589 5.51037C7.4319 5.51037 6.8819 5.97237 6.8819 5.97237L6.1119 5.01437C6.1119 5.01437 6.8704 4.22137 8.1794 4.22137C9.4224 4.22137 10.5769 4.99137 10.5769 6.28937C10.5769 8.10537 8.6634 8.31437 8.6634 9.50287L8.6644 9.50337Z" fill="#3CAA3C" data-sentry-element="path" data-sentry-source-file="question-green-icon.tsx" />
      </g>
      <defs data-sentry-element="defs" data-sentry-source-file="question-green-icon.tsx">
        <clipPath id="clip0_27338_71435" data-sentry-element="clipPath" data-sentry-source-file="question-green-icon.tsx">
          <rect width="16" height="16" fill="white" data-sentry-element="rect" data-sentry-source-file="question-green-icon.tsx" />
        </clipPath>
      </defs>
    </svg>;
};