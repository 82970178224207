import { IIconComponent } from "@/services/types/types";
import { FC } from "react";
export const GoogleplayIcon: FC<IIconComponent> = ({
  className
}) => {
  return <svg width="112" height="25" viewBox="0 0 112 25" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} data-sentry-element="svg" data-sentry-component="GoogleplayIcon" data-sentry-source-file="googleplay-icon.tsx">
      <path d="M16.3233 9.87366L6.50214 4.17104C5.83547 3.78394 5 4.26493 5 5.03582V19.5618C5 20.3327 5.83547 20.8137 6.50213 20.4266L16.3233 14.724M16.3233 9.87366L19.0106 11.434C19.6745 11.8195 19.6745 12.7782 19.0106 13.1636L16.3233 14.724M16.3233 9.87366L5.5 20.2988M16.3233 14.724L6 4.29883" stroke="#F7F8F9" data-sentry-element="path" data-sentry-source-file="googleplay-icon.tsx" />
      <path d="M42.122 10.4514V11.2914C42.122 12.7567 41.6647 13.9281 40.75 14.8054C39.8447 15.6734 38.692 16.1074 37.292 16.1074C35.7707 16.1074 34.506 15.6174 33.498 14.6374C32.4993 13.6574 32 12.4534 32 11.0254C32 9.59741 32.4947 8.38874 33.484 7.39941C34.4827 6.41007 35.7007 5.91541 37.138 5.91541C38.0527 5.91541 38.888 6.12074 39.644 6.53141C40.4093 6.94207 41.002 7.48341 41.422 8.15541L39.518 9.24741C39.3033 8.91141 38.9813 8.63607 38.552 8.42141C38.132 8.20674 37.656 8.09941 37.124 8.09941C36.2933 8.09941 35.6027 8.37474 35.052 8.92541C34.5107 9.47607 34.24 10.1807 34.24 11.0394C34.24 11.8887 34.52 12.5841 35.08 13.1254C35.64 13.6574 36.3867 13.9234 37.32 13.9234C38.6173 13.9234 39.4527 13.4194 39.826 12.4114H37.208V10.4514H42.122Z" fill="white" data-sentry-element="path" data-sentry-source-file="googleplay-icon.tsx" />
      <path d="M49.3068 15.0434C48.5882 15.7527 47.7108 16.1074 46.6748 16.1074C45.6388 16.1074 44.7615 15.7527 44.0428 15.0434C43.3242 14.3247 42.9648 13.4474 42.9648 12.4114C42.9648 11.3754 43.3242 10.5027 44.0428 9.79341C44.7615 9.07474 45.6388 8.71541 46.6748 8.71541C47.7108 8.71541 48.5882 9.07474 49.3068 9.79341C50.0255 10.5027 50.3848 11.3754 50.3848 12.4114C50.3848 13.4474 50.0255 14.3247 49.3068 15.0434ZM45.5268 13.6014C45.8348 13.9094 46.2175 14.0634 46.6748 14.0634C47.1322 14.0634 47.5148 13.9094 47.8228 13.6014C48.1308 13.2934 48.2848 12.8967 48.2848 12.4114C48.2848 11.9261 48.1308 11.5294 47.8228 11.2214C47.5148 10.9134 47.1322 10.7594 46.6748 10.7594C46.2175 10.7594 45.8348 10.9134 45.5268 11.2214C45.2188 11.5294 45.0648 11.9261 45.0648 12.4114C45.0648 12.8967 45.2188 13.2934 45.5268 13.6014Z" fill="white" data-sentry-element="path" data-sentry-source-file="googleplay-icon.tsx" />
      <path d="M57.5647 15.0434C56.846 15.7527 55.9687 16.1074 54.9327 16.1074C53.8967 16.1074 53.0193 15.7527 52.3007 15.0434C51.582 14.3247 51.2227 13.4474 51.2227 12.4114C51.2227 11.3754 51.582 10.5027 52.3007 9.79341C53.0193 9.07474 53.8967 8.71541 54.9327 8.71541C55.9687 8.71541 56.846 9.07474 57.5647 9.79341C58.2833 10.5027 58.6427 11.3754 58.6427 12.4114C58.6427 13.4474 58.2833 14.3247 57.5647 15.0434ZM53.7847 13.6014C54.0927 13.9094 54.4753 14.0634 54.9327 14.0634C55.39 14.0634 55.7727 13.9094 56.0807 13.6014C56.3887 13.2934 56.5427 12.8967 56.5427 12.4114C56.5427 11.9261 56.3887 11.5294 56.0807 11.2214C55.7727 10.9134 55.39 10.7594 54.9327 10.7594C54.4753 10.7594 54.0927 10.9134 53.7847 11.2214C53.4767 11.5294 53.3227 11.9261 53.3227 12.4114C53.3227 12.8967 53.4767 13.2934 53.7847 13.6014Z" fill="white" data-sentry-element="path" data-sentry-source-file="googleplay-icon.tsx" />
      <path d="M64.9685 8.91141H67.0125V15.5614C67.0125 16.6627 66.6531 17.4934 65.9345 18.0534C65.2158 18.6227 64.3431 18.9074 63.3165 18.9074C61.7391 18.9074 60.6331 18.3427 59.9985 17.2134L61.7905 16.1774C62.1078 16.7654 62.6398 17.0594 63.3865 17.0594C63.8811 17.0594 64.2685 16.9287 64.5485 16.6674C64.8285 16.4154 64.9685 16.0467 64.9685 15.5614V14.9174C64.4831 15.5241 63.8018 15.8274 62.9245 15.8274C61.9445 15.8274 61.1231 15.4821 60.4605 14.7914C59.8071 14.0914 59.4805 13.2514 59.4805 12.2714C59.4805 11.2914 59.8071 10.4561 60.4605 9.76541C61.1231 9.06541 61.9445 8.71541 62.9245 8.71541C63.8018 8.71541 64.4831 9.01874 64.9685 9.62541V8.91141ZM62.0565 13.4474C62.3738 13.7461 62.7798 13.8954 63.2745 13.8954C63.7691 13.8954 64.1751 13.7461 64.4925 13.4474C64.8098 13.1487 64.9685 12.7567 64.9685 12.2714C64.9685 11.7861 64.8098 11.3941 64.4925 11.0954C64.1751 10.7967 63.7691 10.6474 63.2745 10.6474C62.7798 10.6474 62.3738 10.7967 62.0565 11.0954C61.7391 11.3941 61.5805 11.7861 61.5805 12.2714C61.5805 12.7567 61.7391 13.1487 62.0565 13.4474Z" fill="white" data-sentry-element="path" data-sentry-source-file="googleplay-icon.tsx" />
      <path d="M68.5531 15.9114V5.69141H70.6531V15.9114H68.5531Z" fill="white" data-sentry-element="path" data-sentry-source-file="googleplay-icon.tsx" />
      <path d="M74.0658 13.2514C74.3085 13.9047 74.8685 14.2314 75.7458 14.2314C76.3152 14.2314 76.7632 14.0541 77.0898 13.6994L78.7698 14.6654C78.0792 15.6267 77.0618 16.1074 75.7178 16.1074C74.5418 16.1074 73.5992 15.7574 72.8898 15.0574C72.1898 14.3574 71.8398 13.4754 71.8398 12.4114C71.8398 11.3567 72.1852 10.4794 72.8758 9.77941C73.5758 9.07007 74.4718 8.71541 75.5638 8.71541C76.5812 8.71541 77.4258 9.07007 78.0978 9.77941C78.7792 10.4794 79.1198 11.3567 79.1198 12.4114C79.1198 12.7101 79.0918 12.9901 79.0358 13.2514H74.0658ZM74.0238 11.6834H77.0338C76.8285 10.9461 76.3338 10.5774 75.5498 10.5774C74.7378 10.5774 74.2292 10.9461 74.0238 11.6834Z" fill="white" data-sentry-element="path" data-sentry-source-file="googleplay-icon.tsx" />
      <path d="M87.5909 6.11141C88.5523 6.11141 89.3643 6.43807 90.0269 7.09141C90.6896 7.74474 91.0209 8.53807 91.0209 9.47141C91.0209 10.4047 90.6896 11.1981 90.0269 11.8514C89.3643 12.5047 88.5523 12.8314 87.5909 12.8314H86.1209V15.9114H83.8809V6.11141H87.5909ZM87.5909 10.7314C87.9363 10.7314 88.2209 10.6101 88.4449 10.3674C88.6689 10.1247 88.7809 9.82607 88.7809 9.47141C88.7809 9.11674 88.6689 8.81807 88.4449 8.57541C88.2209 8.33274 87.9363 8.21141 87.5909 8.21141H86.1209V10.7314H87.5909Z" fill="white" data-sentry-element="path" data-sentry-source-file="googleplay-icon.tsx" />
      <path d="M92.0688 15.9114V5.69141H94.1688V15.9114H92.0688Z" fill="white" data-sentry-element="path" data-sentry-source-file="googleplay-icon.tsx" />
      <path d="M100.787 8.91141H102.887V15.9114H100.787V15.2534C100.293 15.8227 99.5975 16.1074 98.7015 16.1074C97.7775 16.1074 96.9888 15.7527 96.3355 15.0434C95.6821 14.3247 95.3555 13.4474 95.3555 12.4114C95.3555 11.3754 95.6821 10.5027 96.3355 9.79341C96.9888 9.07474 97.7775 8.71541 98.7015 8.71541C99.5975 8.71541 100.293 9.00007 100.787 9.56941V8.91141ZM97.9175 13.6574C98.2255 13.9654 98.6268 14.1194 99.1215 14.1194C99.6161 14.1194 100.017 13.9654 100.325 13.6574C100.633 13.3494 100.787 12.9341 100.787 12.4114C100.787 11.8887 100.633 11.4734 100.325 11.1654C100.017 10.8574 99.6161 10.7034 99.1215 10.7034C98.6268 10.7034 98.2255 10.8574 97.9175 11.1654C97.6095 11.4734 97.4555 11.8887 97.4555 12.4114C97.4555 12.9341 97.6095 13.3494 97.9175 13.6574Z" fill="white" data-sentry-element="path" data-sentry-source-file="googleplay-icon.tsx" />
      <path d="M108.838 8.91141H111.078L108.684 15.7014C108.292 16.8027 107.774 17.5914 107.13 18.0674C106.495 18.5434 105.683 18.7581 104.694 18.7114V16.7514C105.179 16.7514 105.553 16.6627 105.814 16.4854C106.075 16.3174 106.285 16.0187 106.444 15.5894L103.658 8.91141H105.968L107.522 13.1954L108.838 8.91141Z" fill="white" data-sentry-element="path" data-sentry-source-file="googleplay-icon.tsx" />
    </svg>;
};