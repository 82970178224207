import { IIconComponent } from "@/services/types/types";
import { FC } from "react";
export const TextImageRightIcon: FC<IIconComponent> = ({
  className,
  fill
}) => {
  return <svg className={className} fill={fill ? fill : "none"} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="TextImageRightIcon" data-sentry-source-file="text-image-right-icon.tsx">
      <rect x="2" y="5" width="10" height="14" rx="2" fill="#2D264B" data-sentry-element="rect" data-sentry-source-file="text-image-right-icon.tsx" />
      <rect x="14" y="5" width="7" height="2" rx="1" fill="#2D264B" data-sentry-element="rect" data-sentry-source-file="text-image-right-icon.tsx" />
      <rect x="13" y="9" width="9" height="2" rx="1" fill="#2D264B" data-sentry-element="rect" data-sentry-source-file="text-image-right-icon.tsx" />
      <rect x="14" y="13" width="7" height="2" rx="1" fill="#2D264B" data-sentry-element="rect" data-sentry-source-file="text-image-right-icon.tsx" />
      <rect x="13" y="17" width="9" height="2" rx="1" fill="#2D264B" data-sentry-element="rect" data-sentry-source-file="text-image-right-icon.tsx" />
    </svg>;
};