import { IIconComponent } from "@/services/types/types";
import { FC } from "react";
export const StatusIcon: FC<IIconComponent> = ({
  className
}) => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className={className} data-sentry-element="svg" data-sentry-component="StatusIcon" data-sentry-source-file="status-icon.tsx">
      <path d="M8 4.5C8 5.88071 6.88071 7 5.5 7C4.11929 7 3 5.88071 3 4.5C3 3.11929 4.11929 2 5.5 2C6.88071 2 8 3.11929 8 4.5Z" fill="#1D1D1D" data-sentry-element="path" data-sentry-source-file="status-icon.tsx" />
      <path d="M5.5 4.5H16.5179C18.441 4.5 20 6.05901 20 7.98214V7.98214C20 9.90528 18.441 11.4643 16.5179 11.4643H7.51786C5.575 11.4643 4 13.0393 4 14.9821V14.9821C4 16.925 5.575 18.5 7.51786 18.5H19" stroke="#1D1D1D" strokeWidth="2" strokeLinecap="round" data-sentry-element="path" data-sentry-source-file="status-icon.tsx" />
      <path d="M20 18.5C20 19.8807 18.8807 21 17.5 21C16.1193 21 15 19.8807 15 18.5C15 17.1193 16.1193 16 17.5 16C18.8807 16 20 17.1193 20 18.5Z" fill="white" stroke="#1D1D1D" strokeWidth="2" data-sentry-element="path" data-sentry-source-file="status-icon.tsx" />
    </svg>;
};