import { FC } from "react";
import { IIconComponent } from "@/services/types/types";
export const PaletteIcon: FC<IIconComponent> = ({
  className
}) => {
  return <svg width="800px" height="800px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" version="1.1" id="svg6190" className={className} data-sentry-element="svg" data-sentry-component="PaletteIcon" data-sentry-source-file="palette-icon.tsx">
      <rect style={{
      opacity: 1,
      vectorEffect: "none",
      fill: "#373737",
      fillOpacity: 1,
      stroke: "none",
      strokeWidth: 3.20000005,
      strokeLinecap: "square",
      strokeLinejoin: "round",
      strokeMiterlimit: 4,
      strokeDasharray: "none",
      strokeDashoffset: 3.20000005,
      strokeOpacity: 1
    }} id="rect1290" width="16" height="16" x="0" y="0" data-sentry-element="rect" data-sentry-source-file="palette-icon.tsx" />
      <rect y="1" x="1" height="4.0000005" width="4.0000005" id="rect6829-4" style={{
      display: "inline",
      fill: "#5da1f3",
      fillOpacity: 1,
      stroke: "none",
      strokeWidth: 1.15467715
    }} data-sentry-element="rect" data-sentry-source-file="palette-icon.tsx" />
      <rect style={{
      display: "inline",
      fill: "#5dd3f3",
      fillOpacity: 1,
      stroke: "none",
      strokeWidth: 1.15467715
    }} id="rect6831-7" width="4.0000005" height="4.0000005" x="6" y="1" data-sentry-element="rect" data-sentry-source-file="palette-icon.tsx" />
      <rect y="1" x="11" height="4.0000005" width="4.0000005" id="rect6833-1" style={{
      display: "inline",
      fill: "#5df3e3",
      fillOpacity: 1,
      stroke: "none",
      strokeWidth: 1.15467715
    }} data-sentry-element="rect" data-sentry-source-file="palette-icon.tsx" />
      <rect style={{
      display: "inline",
      fill: "#5dd3f3",
      fillOpacity: 1,
      stroke: "none",
      strokeWidth: 1.15467715
    }} id="rect6835-3" width="4.0000005" height="3.999944" x="1" y="6" data-sentry-element="rect" data-sentry-source-file="palette-icon.tsx" />
      <rect y="11" x="1" height="4.0000005" width="4.0000005" id="rect6837-2" style={{
      display: "inline",
      fill: "#f3c05d",
      fillOpacity: 1,
      stroke: "none",
      strokeWidth: 1.15467715
    }} data-sentry-element="rect" data-sentry-source-file="palette-icon.tsx" />
      <rect y="6" x="6" height="3.999944" width="4.0000005" id="rect6839-1" style={{
      display: "inline",
      fill: "#f35d99",
      fillOpacity: 1,
      stroke: "none",
      strokeWidth: 1.15466905
    }} data-sentry-element="rect" data-sentry-source-file="palette-icon.tsx" />
      <rect style={{
      display: "inline",
      fill: "#d55df3",
      fillOpacity: 1,
      stroke: "none",
      strokeWidth: 1.15466905
    }} id="rect6841-6" width="4.0000005" height="3.999944" x="11" y="6" data-sentry-element="rect" data-sentry-source-file="palette-icon.tsx" />
      <rect style={{
      display: "inline",
      fill: "#d5f35d",
      fillOpacity: 1,
      stroke: "none",
      strokeWidth: 1.15467715
    }} id="rect6843-3" width="4.0000005" height="4.0000005" x="6" y="11" data-sentry-element="rect" data-sentry-source-file="palette-icon.tsx" />
      <rect y="11" x="11" height="4.0000005" width="4.0000005" id="rect6845-8" style={{
      display: "inline",
      fill: "#8bf35d",
      fillOpacity: 1,
      stroke: "none",
      strokeWidth: 1.15467715
    }} data-sentry-element="rect" data-sentry-source-file="palette-icon.tsx" />
    </svg>;
};