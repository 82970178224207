import { IIconComponent } from "@/services/types/types";
import { FC } from "react";
export const AppQrCodeIcon: FC<IIconComponent> = ({
  className
}) => {
  return <svg className={className} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="svg" data-sentry-component="AppQrCodeIcon" data-sentry-source-file="app-qr-code-icon.tsx">
      <rect width="5" height="5" x="3" y="3" rx="1" data-sentry-element="rect" data-sentry-source-file="app-qr-code-icon.tsx" />
      <rect width="5" height="5" x="16" y="3" rx="1" data-sentry-element="rect" data-sentry-source-file="app-qr-code-icon.tsx" />
      <rect width="5" height="5" x="3" y="16" rx="1" data-sentry-element="rect" data-sentry-source-file="app-qr-code-icon.tsx" />
      <path d="M21 16h-3a2 2 0 0 0-2 2v3" data-sentry-element="path" data-sentry-source-file="app-qr-code-icon.tsx" />
      <path d="M21 21v.01" data-sentry-element="path" data-sentry-source-file="app-qr-code-icon.tsx" />
      <path d="M12 7v3a2 2 0 0 1-2 2H7" data-sentry-element="path" data-sentry-source-file="app-qr-code-icon.tsx" />
      <path d="M3 12h.01" data-sentry-element="path" data-sentry-source-file="app-qr-code-icon.tsx" />
      <path d="M12 3h.01" data-sentry-element="path" data-sentry-source-file="app-qr-code-icon.tsx" />
      <path d="M12 16v.01" data-sentry-element="path" data-sentry-source-file="app-qr-code-icon.tsx" />
      <path d="M16 12h1" data-sentry-element="path" data-sentry-source-file="app-qr-code-icon.tsx" />
      <path d="M21 12v.01" data-sentry-element="path" data-sentry-source-file="app-qr-code-icon.tsx" />
      <path d="M12 21v-1" data-sentry-element="path" data-sentry-source-file="app-qr-code-icon.tsx" />
    </svg>;
};