import { FC } from "react";
import { IIconComponent } from "@/services/types/types";
export const LinkIcon: FC<IIconComponent> = ({
  className
}) => {
  return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} data-sentry-element="svg" data-sentry-component="LinkIcon" data-sentry-source-file="link-icon.tsx">
      <path d="M8 6.25C4.82436 6.25 2.25 8.82436 2.25 12C2.25 15.1756 4.82436 17.75 8 17.75C8.41421 17.75 8.75 17.4142 8.75 17C8.75 16.5858 8.41421 16.25 8 16.25C5.65279 16.25 3.75 14.3472 3.75 12C3.75 9.65279 5.65279 7.75 8 7.75C8.41421 7.75 8.75 7.41421 8.75 7C8.75 6.58579 8.41421 6.25 8 6.25Z" fill="#2D264B" data-sentry-element="path" data-sentry-source-file="link-icon.tsx" />
      <path d="M16 6.25C15.5858 6.25 15.25 6.58579 15.25 7C15.25 7.41421 15.5858 7.75 16 7.75C18.3472 7.75 20.25 9.65279 20.25 12C20.25 14.3472 18.3472 16.25 16 16.25C15.5858 16.25 15.25 16.5858 15.25 17C15.25 17.4142 15.5858 17.75 16 17.75C19.1756 17.75 21.75 15.1756 21.75 12C21.75 8.82436 19.1756 6.25 16 6.25Z" fill="#2D264B" data-sentry-element="path" data-sentry-source-file="link-icon.tsx" />
      <path d="M8 11.25C7.58579 11.25 7.25 11.5858 7.25 12C7.25 12.4142 7.58579 12.75 8 12.75H16C16.4142 12.75 16.75 12.4142 16.75 12C16.75 11.5858 16.4142 11.25 16 11.25H8Z" fill="#2D264B" data-sentry-element="path" data-sentry-source-file="link-icon.tsx" />
    </svg>;
};