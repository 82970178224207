import { IIconComponent } from "@/services/types/types";
import { FC } from "react";
export const BrandsIcon: FC<IIconComponent> = ({
  className
}) => {
  return <svg className={className} width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="BrandsIcon" data-sentry-source-file="brands-icon.tsx">
      <path d="M2.95481 13.7413C2.90347 13.6763 2.91455 13.582 2.97956 13.5306L12.6808 5.86858C12.7108 5.84492 12.7487 5.83358 12.7867 5.83685L19.0316 6.37473C19.0625 6.37739 19.0909 6.3927 19.1102 6.41703C19.129 6.44083 19.1373 6.47122 19.1334 6.50129L18.3023 12.8337C18.2972 12.8724 18.2772 12.9076 18.2465 12.9319L8.55318 20.5877C8.48816 20.639 8.39384 20.6279 8.34249 20.5629L2.95481 13.7413Z" stroke="#1D1D1D" strokeWidth="1.7" data-sentry-element="path" data-sentry-source-file="brands-icon.tsx" />
      <circle cx="15.1424" cy="9.04156" r="1" transform="rotate(-8.88458 15.1424 9.04156)" fill="#1D1D1D" data-sentry-element="circle" data-sentry-source-file="brands-icon.tsx" />
      <path d="M8.68359 11.9824L11.2325 15.2098" stroke="#1D1D1D" strokeWidth="1.7" strokeLinecap="round" data-sentry-element="path" data-sentry-source-file="brands-icon.tsx" />
      <path d="M6.50391 13.9873L9.05286 17.2147" stroke="#1D1D1D" strokeWidth="1.7" strokeLinecap="round" data-sentry-element="path" data-sentry-source-file="brands-icon.tsx" />
      <path d="M15.5019 8.99919C19.6694 7.648 22.0637 4.98622 21.6037 3.04644C21 0.5 16.6323 0.39197 15.0247 5.56607" stroke="#1D1D1D" strokeWidth="1.7" strokeLinecap="round" data-sentry-element="path" data-sentry-source-file="brands-icon.tsx" />
    </svg>;
};