import { IIconComponent } from "@/services/types/types";
import { FC } from "react";
export const BigHeartIcon: FC<IIconComponent> = props => {
  const {
    className
  } = props;
  return <svg className={className} width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="BigHeartIcon" data-sentry-source-file="big-heart-icon.tsx">
      <path d="M0 8C0 3.58172 3.58172 0 8 0H42C46.4183 0 50 3.58172 50 8V42C50 46.4183 46.4183 50 42 50H8C3.58172 50 0 46.4183 0 42V8Z" fill="#F7F8F9" data-sentry-element="path" data-sentry-source-file="big-heart-icon.tsx" />
      <path d="M33.1771 10C31.5533 10.0067 29.9565 10.4233 28.5295 11.2125C27.1025 12.0018 25.8898 13.1391 25 14.5227C23.8344 12.7387 22.1373 11.3831 20.1585 10.6553C18.1798 9.92758 16.0239 9.86617 14.0083 10.4802C11.9927 11.0941 10.2239 12.3511 8.96225 14.0659C7.70059 15.7808 7.01276 17.8629 7 20.0058C7 35.0058 25 40 25 40C25 40 43 35.0058 43 20.0058C43.0022 18.6912 42.7497 17.3891 42.2569 16.1741C41.764 14.9591 41.0406 13.8551 40.128 12.9255C39.2154 11.9959 38.1316 11.259 36.9388 10.757C35.7461 10.255 34.4677 9.99771 33.1771 10Z" stroke="#9EA0A3" strokeWidth="2" strokeMiterlimit="10" data-sentry-element="path" data-sentry-source-file="big-heart-icon.tsx" />
      <path d="M33.0615 17C33.448 16.9977 33.831 17.0819 34.1884 17.2476C34.5459 17.4133 34.8706 17.6573 35.1439 17.9655C35.4171 18.2737 35.6335 18.6399 35.7804 19.043C35.9274 19.4461 36.002 19.8781 36 20.3139C35.9782 21.7557 35.7016 23.178 35.1865 24.4978C34.6714 25.8175 33.9281 27.0081 33 28" stroke="#9EA0A3" strokeWidth="2" strokeMiterlimit="10" data-sentry-element="path" data-sentry-source-file="big-heart-icon.tsx" />
    </svg>;
};